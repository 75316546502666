import React from 'react'
import building from '../../images/si5.png'
import './mainheader.css'
const MainHeader = () => {
  return (
    <header className='main-header'>
      <div className='main-header-cont'>
        <div className='main-header-left'>
          <h4>Nest-One</h4>
          <h1>Luxurious Accommodation For Boys</h1>
          <p>Boys Hostel in Shivajinagar</p>

          <button className='btn lg'>
            <a href='#gallery' rel="noreferrer" className='room-link'>
              View Rooms
            </a>
          </button>

        </div>
        <div className='main-header-right'>
            <img src={building} alt='Loading...' />
        </div>
      </div>
    </header>
  )
}

export default MainHeader
