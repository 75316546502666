import React from 'react'
import './gallery.css'

const Gallery = () => {
  const galleryLength = 5
  const images = []

  for (let i = 1; i <= galleryLength; i++) {
    images.push(require(`../../images/si${i}.png`))
  }
  return (
    <div className='gallery-container' id="gallery">
      <div className='tag-cont'>
        <div className='tagline-cont'>
          <p className='tagline'>View Rooms</p>
        </div>
      </div>

      <section>
        <div className='container gallery-cont'>
          {images.map((image, idx) => {
            return (
              <article key={idx}>
                <img src={image} alt={`Gallery Image ${idx + 1}`} />
              </article>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default Gallery
