import React from 'react'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from 'react-icons/io'
import Logo from '../../images/logo.png'
import './footer.css'
const Footer = () => {
  return (
    <footer>
      <div className='container footer-cont'>
        <article>
          <Link to='/' className='logo'>
            <img src={Logo} alt='Footer Logo' />
          </Link>
          <p>
            Address: Near, 955/56, Vidya Vilas Housing Society, Deep Bunglow
            Chowk, Shivajinagar, Pune, Maharashtra 411016
          </p>
        </article>

        <article>
          <h2>Get In Touch</h2>
          <h3>Contact or WhatsApp</h3>
          <h3>9975742499</h3>
          <div className='footer-socials'>
            <a
              href='https://wa.me/+919975742499'
              target='_blank'
              rel='noreferrer noopener'
            >
              <IoLogoWhatsapp />
            </a>
          </div>
        </article>

        <article>
          <h2>Business Hours</h2>
          <h3>Mon: Open 24 hours</h3>
          <h3>Tue: Open 24 hours</h3>
          <h3>Wed: Open 24 hours</h3>
          <h3>Thu: Open 24 hours</h3>
          <h3>Fri: Open 24 hours</h3>
          <h3>Sat: Open 24 hours</h3>
          <h3>Sun: Open 24 hours</h3>
        </article>
      </div>

      <div className='footer-copyright'>
        <small>2023 &copy; All Rights Reserved </small>
      </div>
    </footer>
  )
}

export default Footer
