import Navbar from './components/navbar/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Footer from './components/footer/Footer'
import "zone.js";
import { IoLogoWhatsapp } from 'react-icons/io'

function App () {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
        <div className='footer-socials' style={{position: "fixed", bottom: '1rem', right: "1rem"}}>
          <a
            href='https://wa.me/+919975742499'
            target='_blank'
            rel='noreferrer noopener'
          >
            <IoLogoWhatsapp />
          </a>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
