import React from 'react'

const SectionHead = ({ icon, title, className }) => {
  return (
    <div className={`section-head ${className}`}>
      <span>
        {icon} {title}
      </span>
    </div>
  )
}

export default SectionHead
