import React from 'react'
import { plans } from '../../data'
import { BsFillDoorOpenFill } from 'react-icons/bs'
import Card from '../../UI/Card'
import './rooms.css'
import SectionHead from '../sectionHead/SectionHead';
import { ArrowRightOutlined } from '@ant-design/icons'

const Rooms = () => {
  return (
    <section id='rooms'>
      <SectionHead className='container' icon={<BsFillDoorOpenFill />} title='Rooms' />
      <div className='container rooms-cont'>
        {plans.map( ( { id, desc, sharing, features } ) => {
          return (
            <Card key={id} className='room'>
              <small>{desc}</small>
              <h1>{sharing}</h1>
              {features.map( ( { feature, available }, idx ) => {
                return (
                  <p key={idx} className={!available ? 'disabled' : ''}>
                    <ArrowRightOutlined style={{fontSize: "16px", padding: "0", margin: "0", width: "50px", display: 'flex', alignItems: "center", justifyContent: "flex-start"}} /> {feature}
                  </p>
                )
              } )}
            </Card>
          )
        } )}
      </div>
    </section>
  )
}

export default Rooms
