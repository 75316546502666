import { MdFoodBank, MdLocalParking } from 'react-icons/md'
import { AiOutlineWifi } from 'react-icons/ai'
import { GiWashingMachine } from 'react-icons/gi'

export const links = [
  {
    name: 'Rooms',
    path: '#rooms',
  },
  {
    name: 'Gallery',
    path: '#gallery',
  },
]

export const location = {
  name: 'Location',
  path: 'https://www.google.com/maps/place/Nest+One+Hostel/@18.5278296,73.8340071,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2bf827629659b:0x91ab218a779fe58e!8m2!3d18.5278296!4d73.8340071!16s%2Fg%2F11b6jb3696',
}

export const images = [
  require('../src/images/si1.png'),
  require('../src/images/si2.png'),
  require('../src/images/si3.png'),
  require('../src/images/si4.png'),
  require('../src/images/si5.png'),
]

export const plans = [
  {
    id: 1,
    sharing: 'Two sharing',
    features: [
      { feature: 'Attached/Non-Attached Washroom', available: true },
      { feature: 'AC', available: true },
      { feature: 'Table Chair', available: true },
      { feature: 'Cupboards', available: true },
      { feature: 'Shoe racks outside room', available: true },
    ],
  },
  {
    id: 2,
    sharing: 'Three sharing',
    features: [
      { feature: 'Attached Washroom', available: true },
      { feature: 'AC', available: true },
      { feature: 'Table Chair', available: true },
      { feature: 'Cupboards', available: true },
      { feature: 'Shoe racks outside room', available: true },
    ],
  },
  {
    id: 3,
    sharing: 'Four Sharing',
    price: 89.99,
    features: [
      { feature: 'Attached Washroom ', available: true },
      { feature: 'AC', available: true },
      { feature: 'Table Chair', available: true },
      { feature: 'Cupboards', available: true },
      { feature: 'Shoe racks outside room', available: true },
    ],
  },
]

export const facility = [
  {
    id: 1,
    icon: <MdFoodBank />,
    title: 'Food',
    info: 'We serve the delicious food',
  },
  {
    id: 2,
    icon: <AiOutlineWifi />,
    title: 'Wifi',
    info: '24 hours wifi available for all your work',
  },
  {
    id: 3,
    icon: <GiWashingMachine />,
    title: 'Laundry',
    info: 'No need to postpone laundry day',
  },
  {
    id: 4,
    icon: <MdLocalParking />,
    title: 'Parking - Two wheelers',
    info: 'We have adequate space for two wheeler parking',
  },
]
