import React from 'react'
import { BiMap } from 'react-icons/bi'
import {MdHotelClass} from 'react-icons/md'
import SectionHead from '../sectionHead/SectionHead'
import { facility } from '../../data'
import Card from '../../UI/Card'
import './facility.css'
import { Link } from 'react-router-dom'
const Facility = () => {
  return (
    <section className='facility'>
      <div className='container facility-cont'>
        <SectionHead icon={<MdHotelClass />} title='Facilities' />
        <div className='facility-wrapper'>
          {facility.map(({ id, icon, title, info, path }) => {
            return (
              <Card className='facility-program' key={id}>
                <span>{icon}</span>
                <h4>{title} </h4>
                <small>{info}</small>
              </Card>
            )
          })}
        </div>
      </div>

      <SectionHead className='container' icon={<BiMap />} title='Map' />
      <Link to='https://www.google.com/maps/place/Nest+One+Hostel/@18.5278296,73.8340071,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2bf827629659b:0x91ab218a779fe58e!8m2!3d18.5278296!4d73.8340071!16s%2Fg%2F11b6jb3696' target='__blank'>
        <div className='container'>
          <img  className='container' src='https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=en&region=IN&markers=color:0x5f9bce|18.5278468,73.8340397&zoom=16&client=google-presto&signature=uPqAi6o5JI1F3DaLVPKr3g0aOHM' alt=''/>
        </div>
      </Link>
    </section>
  )
}

export default Facility
