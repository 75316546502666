import React, {useEffect, useRef } from 'react'
import './revealAnimation.css'

const RevealAnimation = ({ children }) => {
  const revealRef = useRef(null)

  useEffect(() => {
    const revealElement = revealRef.current

    const handleScroll = () => {
      const revealTop = revealElement.offsetTop
      const revealHeight = revealElement.clientHeight
      const windowHeight = window.innerHeight

      if (window.pageYOffset >= revealTop - windowHeight + revealHeight / 2) {
        revealElement.classList.add('revealed')
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className='scroll-reveal' ref={revealRef}>
      {children}
    </div>
  )
}

export default RevealAnimation
