import React from 'react'
import Gallery from '../components/gallery/Gallery'
import MainHeader from '../components/mainHeader/MainHeader'
import Rooms from '../components/rooms/Rooms'
import Facility from '../components/facility/Facility'
import RevealAnimation from '../components/revealAnimation/RevealAnimation'
const Home = () => {
  return (
    <div>
      <MainHeader />
      <RevealAnimation>
        <Gallery />
      </RevealAnimation>
      <RevealAnimation>
        <Rooms />
      </RevealAnimation>
      <RevealAnimation>
        <Facility />
      </RevealAnimation>
    </div>
  )
}

export default Home
