import React, { useState } from 'react'
import Logo from '../../images/logo.png'
import { GoThreeBars } from 'react-icons/go'
import { FaTimes } from 'react-icons/fa'
import { Link} from 'react-router-dom'
import { links, location } from '../../data'
import './navbar.css'
const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false)
  return (
    <nav>
      <div className='container nav-cont'>
        <Link to='/' className='logo' onClick={() => setIsNavVisible(false)}>
          <img src={Logo} alt='Loading' />
        </Link>
        <ul className={`nav-links ${isNavVisible ? 'show-nav' : 'hide-nav'}`}>
          {links.map(({ name, path }, idx) => {
            return (
              <li key={idx}>
                <a
                  href={path}
                  rel="noreferrer"
                  onClick={() => setIsNavVisible((prev) => !prev)}
                >
                  {name}
                </a>
              </li>
            )
          })}
          <a href={location.path} rel="noreferrer" className='location'  target='_blank'>{location.name}</a>
        </ul>

        <button
          className='nav-toggle-btn'
          onClick={() => setIsNavVisible((prev) => !prev)}
        >
          {isNavVisible ? <FaTimes /> : <GoThreeBars />}
        </button>
      </div>
    </nav>
  )
}

export default Navbar
